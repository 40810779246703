import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  
  {
    path: "/",
    name: "Index",
    component: () =>
    import(
      "../views/Index.vue"
    ),
    props: true,
  },

  {
    path: "/chat/:token",
    name: "chat",
    component: () =>
    import(
      "../views/Site/Chat.vue"
    ),
    props: true,
  },

  {
    path: "/chamado/:protocol",
    name: "chamado",
    component: () =>
      import("../views/Site/chamado.vue"),
  },

  {
    path: "/chamado",
    name: "chamadoseach",
    component: () =>
      import("../views/Site/chamado.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    
  },
});



export default router;
