import axios from "axios";
import Vue from "vue";
import decode from 'jwt-decode';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL,
  requestCert: false,
  strictSSL: false,
  rejectUnauthorized: false,
});


axiosInstance.interceptors.response.use(response => {
  if(response.status == 401){
     this.$store.dispatch("LOGOUT");
     this.$router.push({ name: "login" });
  }
  if(response.status == 429){
    this.$store.dispatch("LOGOUT");
    this.$router.push({ name: "login" });
 }
  return response
})


export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint, body) {
    return axiosInstance.delete(endpoint, body);
  },
  login(body) {
    return axiosInstance.post("/api/user/authUser", body);
  },
  recuperar(body) {
    return axiosInstance.post("/api/user/resetpassword", body);
  },
  validate() {
    return axiosInstance.get('/api/user/me')

  }
};

export function getCep(cep) {
  return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
}

export function decodeToken(encodedToken){
  let token = decode(encodedToken)
  if (!token.exp) {
      return null
  }

  let date = new Date(0)
  date.setUTCSeconds(token.exp)

  return date

}
 
Vue.prototype.$http = api;

 
Vue.prototype.$apiURL = process.env.VUE_APP_URL

Vue.prototype.$urlBase = process.env.VUE_APP_BASE
Vue.prototype.$urlSite = process.env.VUE_APP_SITE

Vue.prototype.$storage = process.env.VUE_APP_STORAGE
Vue.prototype.$titleSite = process.env.VUE_APP_TITLE
Vue.prototype.$subtitleSite = process.env.VUE_APP_SUBTITLE
Vue.prototype.$wlType = process.env.VUE_APP_WL_TYPE
// Add a 401 response interceptor
// axiosInstance.interceptors.response.use(function (response) {
//   return response;
// },  error => {
//   if (error.response.status === 401) {
//     this.$store.dispatch("LOGOUT");
//     this.$router.push({ name: "login" });
//   }
// });